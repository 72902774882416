"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.messages = void 0;

/*eslint-disable*/
const messages = {
  "action_detail.add_report_link": "+ Add Report Link",
  "action_detail.cost_savings": "Cost savings",
  "action_detail.description_label": "Description",
  "action_detail.estimated_savings_accordion_label": "Estimated Savings",
  "action_detail.estimated_savings_electricity": "Electricity",
  "action_detail.estimated_savings_energy": "Utility",
  "action_detail.estimated_savings_gas": "Gas",
  "action_detail.estimated_savings_operational": "Operational",
  "action_detail.estimated_savings_thermal": "Thermal",
  "action_detail.estimated_savings_water": "Water",
  "action_detail.inline_edit_prompt": "Click to edit",
  "action_detail.insights_and_equipment_accordion_label": "Insights & Equipment",
  "action_detail.links_accordion_label": "Links",
  "action_detail.report_link_label": "Report Link",
  "action_detail.team_and_assignee_accordion_label": "Team & Assignee",
  "action_detail.utility_savings": "Utility savings",
  "action_detail.v2.description_placeholder": "+ Add Description",
  "action_details.statuses.severity": "Severity",
  "action_details.statuses.status": "Status",
  "action_details.statuses.type": "Type",
  "action_form.add_action": "Add Action",
  "action_form.assignee_label": "Assignee",
  "action_form.cancel": "Cancel",
  "action_form.confirm_remove_attachment": "You are about to remove the attachment from this action, continue?",
  "action_form.context_label": "Type",
  "action_form.description_label": "Description",
  "action_form.description_placeholder": "Detailed explanation of the problem",
  "action_form.electricity_field_help": "Estimated annual loss",
  "action_form.electricity_label": "Electricity (kWh)",
  "action_form.energy_savings_field_help": "Expected annual performance impact if the issue is not resolved",
  "action_form.energy_savings_label": "Utility savings ($)",
  "action_form.estimated_savings_heading": "Estimated savings",
  "action_form.gas_field_help": "Estimated annual loss",
  "action_form.gas_label": "Gas (GJ)",
  "action_form.links_heading": "Links",
  "action_form.operational_savings_field_help": "Expected annual non-utility impact if the issue is not resolved",
  "action_form.operational_savings_label": "Operational savings ($)",
  "action_form.priority_label": "Severity",
  "action_form.remove": "Remove",
  "action_form.report_url_label": "Report URL",
  "action_form.savings_heading": "Cost savings",
  "action_form.select_placeholder": "Select...",
  "action_form.site": "Site",
  "action_form.status_label": "Status",
  "action_form.summary_label": "Summary",
  "action_form.team_assignee_heading": "Team & Assignee",
  "action_form.team_label": "Team",
  "action_form.thermal_field_help": "Estimated annual loss",
  "action_form.thermal_label": "Thermal (kWh)",
  "action_form.utility_savings_heading": "Utility savings",
  "action_form.validation.context_cannot_be_blank": "Type cannot be blank",
  "action_form.validation.electricity_savings_greater_or_equal_to_0": "Electricity savings must be a number greater than or equal to 0",
  "action_form.validation.gas_savings_greater_or_equal_to_0": "Gas savings must be a number greater than or equal to 0",
  "action_form.validation.operational_savings_greater_or_equal_to_0": "Operational savings must be a number greater than or equal to 0",
  "action_form.validation.priority_cannot_be_blank": "Priority cannot be blank",
  "action_form.validation.report_url_must_be_a_valid_url": "Report URL must be a valid URL",
  "action_form.validation.responsible_cannot_be_blank": "Responsible cannot be blank",
  "action_form.validation.status_cannot_be_blank": "Status cannot be blank",
  "action_form.validation.summary_cannot_be_blank": "Summary cannot be blank",
  "action_form.validation.summary_cannot_over_255_characters": "Summary cannot be longer than 255 characters",
  "action_form.validation.total_financial_impact_greater_or_equal_to_0": "Total financial impact must be a number greater than or equal to 0",
  "action_form.validation.water_savings_greater_or_equal_to_0": "Water savings must be a number greater than or equal to 0",
  "action_form.water_field_help": "Estimated annual loss",
  "action_form.water_label": "Water (kL)",
  "actions.add_action": "Add Action",
  "actions.closed_actions": "Closed Actions",
  "actions.export": "Export",
  "actions.filters": "Filters",
  "actions.monitoring_actions": "Monitoring Actions",
  "actions.open_actions": "Open Actions",
  "actions.sites": "Sites",
  "actions.v2.include_open_status": "<0>Show <1>Open</1> Actions</0>",
  "actions.v2.status_select.applied": "Applied",
  "actions.v2.status_select.filter_status": "Filter Status",
  "add_message.add_comment_button": "Add Comment",
  "add_message.attach_file_button": "Attach file",
  "add_message.message_placeholder": "Enter your comment...",
  "analysis.analyses": [["insightsCount", "plural", {
    one: "Insight",
    other: "Insights"
  }]],
  "analysis.equips": [["equipCount", "plural", {
    one: "Equip",
    other: "Equips"
  }]],
  "analysis.sites": [["siteCount", "plural", {
    one: "Site",
    other: "Sites"
  }]],
  "app_chrome.actions": "Actions",
  "app_chrome.deployments": "Deployments",
  "app_chrome.explorer": "Explorer",
  "app_chrome.histories": "Histories",
  "app_chrome.insights": "Insights",
  "app_chrome.metering": "Metering",
  "app_chrome.monitoring": "Monitoring",
  "app_chrome.reports": "Reports",
  "attachment_modal.add": "Add",
  "attachment_modal.add_attachment": "Add Attachment",
  "change_password.confirm_new_password_placeholder": "New Password (again)",
  "change_password.current_password": "Current Password",
  "change_password.new_password": "New Password",
  "change_password_modal_data.change_user_password": "Change User Password",
  "change_password_modal_data.confirm_new_password_error_message": "Confirm New Password is required.",
  "change_password_modal_data.current_password_error_message": "Current password is required.",
  "change_password_modal_data.new_password_error_message": "New Password is required.",
  "change_password_modal_data.password_mismatch": "New Password and Confirm New Password do not match. Try re-entering both.",
  "change_password_modal_data.update_password": "Update Password",
  "columns.actions_count": "Actions",
  "columns.affected_equips": "Equips",
  "columns.equip_details": "Details",
  "columns.equip_name": "Equip",
  "columns.est_savings": "Est. savings",
  "columns.id": "ID",
  "columns.latest": "Latest",
  "columns.priority": "Priority",
  "columns.region": "Region",
  "columns.rule_name": "Rule",
  "columns.rule_type": "Rule Type",
  "columns.severity": "Severity",
  "columns.site": "Site",
  "columns.status": "Status",
  "columns.summary": "Summary",
  "columns.team": "Team",
  "columns.triggered_rules": "Hits",
  "columns.type": "Type",
  "columns.updated": "Updated",
  "context_list.data_uptime_label": "Data Uptime",
  "context_list.fdd_label": "FDD",
  "context_list.metering_label": "Metering",
  "context_list.optimisation_label": "Optimisation",
  "context_list.other_label": "Other",
  "context_list.warranty_label": "Warranty",
  "equip_preview.confirm_unassociate": ["You are about to unassociate ", ["equipName"], " from this action, continue?"],
  "equip_preview.description_label": "Description:",
  "equip_preview.duration_label": "Duration:",
  "equip_preview.durations": "Durations",
  "equip_preview.equip_label": "Equip:",
  "equip_preview.hit_details": "Hit Details",
  "equip_preview.latest": "Latest",
  "equip_preview.latest_hit_label": "Latest Hit:",
  "equip_preview.rule_label": "Rule:",
  "equip_preview.severity": "Severity",
  "equip_preview.severity_label": "Severity:",
  "equip_preview.unassociate": "unassociate",
  "equip_preview.view_trend_graph": "View trend graph",
  "estimated_savings.electricity": "Electricity",
  "estimated_savings.gas": "Gas",
  "estimated_savings.operational": "Operational",
  "estimated_savings.thermal": "Thermal",
  "estimated_savings.utility": "Utility",
  "estimated_savings.water": "Water",
  "forgot_password.form_title": "Forgot Password",
  "forgot_password.instructions": "Enter the username or email address assocated with your account. We'll email you with a password reset link.",
  "forgot_password.submit": "Submit",
  "forgot_password.username_placeholder": "Enter your username...",
  "inline_edit.add_assignee": "Add Assignee",
  "inline_edit.assignee_label": "Assignee",
  "inline_edit.team_label": "Team",
  "inline_edit.team_select_placeholder": "Select....",
  "inline_edit.v2.remove_assignee": "Remove",
  "inline_edit_action.attach": "Attach file",
  "inline_edit_action.attach_new": "Attach new",
  "inline_edit_action.cancel_attachment": "Cancel",
  "inline_edit_action.invalid_filetype": "For security purposes this filetype is not allowed. Please select a different file.",
  "inline_edit_action.screenshot": "Screenshot",
  "inline_edit_action.trend_graph_edit": "Trend Graph",
  "inline_edit_action.upload_attachment": "Upload",
  "inline_edit_action.v2.edit_url": "Edit",
  "inline_edit_action.v2.trend_graph_anchor": "View Report",
  "issue_pane.add_comment": "Add Comment",
  "issue_pane.attach_file": "Attach File",
  "issue_pane.close": "Close",
  "language_select.language": "Language",
  "login.forgot_password": "Forgot password?",
  "login.password": "Password",
  "login.sign_in": "Sign In",
  "login.support": "Support",
  "login.username": "Username",
  "mention_box.add_comment": "Add Comment",
  "modal.close": "Close",
  "monitoring.all": "All",
  "monitoring.comfort": "Comfort",
  "monitoring.connectivity": "Connectivity",
  "monitoring.data_uptime": "Data Uptime",
  "monitoring.fdd": "FDD",
  "monitoring.metering": "Metering",
  "monitoring.optimisation": "Optimisation",
  "monitoring.other": "Other",
  "monitoring.performance": "Performance",
  "no_results_overlay.found_for_selected_insight": "0 Active actions found for selected site",
  "no_results_overlay.found_for_selected_sites": "0 results found for your selected sites",
  "no_results_overlay.no_results_message": "0 results match the current filter(s).",
  "not_found.page_not_available": "Page not available. The page requested no longer exists or isn't accessible to you.",
  "not_found.related_links": "Related links",
  "priority_list.blocker": "Blocker",
  "priority_list.critical": "Critical",
  "priority_list.major": "Major",
  "priority_list.minor": "Minor",
  "priority_list.moderate": "Moderate",
  "priority_list.trivial": "Trivial",
  "profile.account": "Account",
  "profile.change_password": "Change Password",
  "profile.display_name": "Display Name",
  "profile.email": "Email",
  "profile.email_notifications": "Email notifications",
  "profile.groups": "Groups",
  "profile.language": "Language",
  "profile.notifications.action_transitioned": "Action transitioned",
  "profile.notifications.action_transitioned.description": "Receive a notification when someone changes the status of an action you created or are assigned to.",
  "profile.notifications.assigned_to_action": "Assigned to action",
  "profile.notifications.assigned_to_action.description": "Receive a notification when someone assigns you to an action.",
  "profile.notifications.comment_added": "Comment added",
  "profile.notifications.comment_added.description": "Receive a notification when someone adds a comment to an action you created or are assigned to.",
  "profile.notifications.critical_action_created": "Critical action created",
  "profile.notifications.critical_action_created.description": "Receive a notification when a new critical action is created.",
  "profile.notifications.disable_all_notifications": "Disable all notifications",
  "profile.notifications.mentions": "Mentions",
  "profile.notifications.mentions.description": "Receive a notification when someone mentions you in a comment.",
  "profile.password": "Password",
  "profile.profile_management": "Manage Profile",
  "profile.projects": "Projects",
  "profile.role": "Role",
  "profile.sites": "Sites",
  "profile.success_message": "User password successfully updated.",
  "profile.title": "Administration",
  "profile.username": "Username",
  "reports.electricity_usage": "Electricity usage",
  "reports.gas_usage": "Gas usage",
  "reports.generator": "Generator",
  "reports.monthly_summary": "Monthly Summary",
  "reports.performance": "Performance",
  "reports.performance_comfort": "Comfort",
  "reports.performance_utilities": "Utilities",
  "reports.performance_workflow": "Workflow",
  "reports.water_usage": "Water usage",
  "reset_password.confirm_new_password_label": "Confirm new password",
  "reset_password.form_title": "Reset Password",
  "reset_password.new_password_label": "New password",
  "reset_password.password_required": "Field cannot be blank",
  "reset_password.password_reset_success": "Password reset success, please login",
  "reset_password.passwords_dont_match": "Passwords don't match",
  "reset_password.reset_your_password": "Reset your password",
  "reset_password.set_new_password": "Set new password",
  "responsible_list.asset_team_building": "Asset Team - Building",
  "responsible_list.asset_team_electrical": "Asset Team - Electrical",
  "responsible_list.asset_team_plumbing": "Asset Team - Plumbing",
  "responsible_list.asset_team_refrigeration": "Asset Team - Refrigeration",
  "responsible_list.bms_contractor": "BMS Contractor",
  "responsible_list.bonfire_engineer": "Consultant Engineer",
  "responsible_list.bueno": "Bueno",
  "responsible_list.consultant_engineer": "Consultant Engineer",
  "responsible_list.emc_energy_optimisation_specialist": "EMC - Energy Optimisation Specialist",
  "responsible_list.emc_operations_it": "EMC - Operations/IT",
  "responsible_list.external_contractors_back_to_back": "External Contractors (Back to Back)",
  "responsible_list.external_contractors_others": "External Contractors (Others)",
  "responsible_list.fmt_hvac": "Field Maintenance Technician (FMT) - HVAC",
  "responsible_list.fmt_refrigeration": "Field Maintenance Technician (FMT) - Refrigeration",
  "responsible_list.mech_contractor": "Mech. Contractor",
  "responsible_list.monitoring": "Monitoring",
  "responsible_list.other": "Other",
  "responsible_list.other_contractor": "Other Contractor",
  "responsible_list.service_team": "Service Team (STL)",
  "responsible_list.site_local": "Site Local",
  "responsible_list.site_national": "Site National",
  "responsible_list.state_admin_manager": "State Admin Manager (SAM)",
  "responsible_list.technical_support_team": "Technical Support Team (TST)",
  "responsible_list.wfm_support_centre": "WFM Support Centre",
  "show_more_actions_footer_row.v2.showing_count": ["Showing ", ["0"], " of ", ["1"]],
  "show_more_actions_footer_row.v3.show_all": ["Show All (", ["0"], ")"],
  "show_more_actions_footer_row.v3.show_more": ["Show More (+", ["0"], ")"],
  "show_more_grouped_rows.show_all": "Show All",
  "show_more_grouped_rows.show_more": "Show More",
  "show_more_grouped_rows.showing_count": ["Showing ", ["currentCount"], " of ", ["totalCount"]],
  "show_more_row.hits_selected": ["Hits (", ["selectedCount"], " selected)"],
  "show_more_row.show_all": "Show all",
  "show_more_row.show_more": "Show More",
  "show_more_row.showing_count": ["Showing <0>", ["visibleCount"], "</0> of <1>", ["filteredCount"], "</1>"],
  "single_action.back_link": "back to Actions",
  "site_menu.cancel": "Cancel",
  "site_menu.clear_all": "Clear all",
  "site_menu.filter_sites": "Filter sites",
  "site_menu.label": ["Viewing ", ["0"], " Sites"],
  "site_menu.projects": "Projects",
  "site_menu.select_site_prompt": "At least one site must be selected",
  "site_menu.selected": ["Selected (", ["0"], ")"],
  "site_menu.sites": ["Sites (", ["0"], ")"],
  "site_menu.update": "Update",
  "sort_select.v2.title": "Sort By",
  "status_list.closed": "Closed",
  "status_list.in_review": "In Review",
  "status_list.new_action": "New Action",
  "status_list.open": "Open",
  "status_list.parked": "Parked",
  "status_list.pending_po": "Pending PO",
  "status_list.rejected": "Rejected",
  "status_list.scope_required": "Scope Required",
  "status_list.to_be_quoted": "To Be Quoted",
  "status_list.to_be_raised_mm": "New Action",
  "status_list.triage": "Triage",
  "status_list.work_in_progress": "In Progress",
  "ticket_search.v2.placeholder": "Search Actions e.g. ‘AHU’ or ‘critical’",
  "time.last_month": "Last Month",
  "time.last_six_months": "Last 6 Months",
  "time.last_three_month": "Last 3 Months",
  "time.last_three_years": "Last 3 years (inc. YTD)",
  "time.last_twelve_months": "Last 12 Months",
  "time.last_two_years": "Last 2 years (inc. YTD)",
  "time.previous_period": "Previous Period",
  "time.same_period_previous_year": "Same Period Previous Year",
  "time.year_to_date": "Year to date",
  "transition_reason_list.cannot_fix": "Cannot fix (known issue)",
  "transition_reason_list.duplicate": "Duplicate",
  "transition_reason_list.false_alert": "False alert",
  "transition_reason_list.fixed": "Fixed",
  "transition_reason_list.low_priority": "Low priority",
  "transition_reason_list.other": "Other",
  "transition_reason_list.too_difficult_expensive": "Too difficult/expensive",
  "user_menu.group_settings": "Group Settings",
  "user_menu.help": "Help",
  "user_menu.language": "Language",
  "user_menu.login": "Login",
  "user_menu.logout": "Logout",
  "user_menu.my_profile": "Profile",
  "user_menu.project_settings": "Project Settings",
  "user_menu.salutation": ["Hi, ", ["0"]],
  "user_menu.support": "Support",
  "user_menu.user_settings": "User Settings",
  "view_action_activity.column_title": "Activity",
  "view_action_activity.no_activity_message": "<0>Add a comment or attach a file to the activity stream</0><1>Tip: send a notification to other users by @’ing them in a comment</1>",
  "view_action_activity.remove": "Remove",
  "view_action_activity.remove_comment_confirmation_text": "Are you sure you want to remove this comment?",
  "view_action_modal.header_title": ["Action ", ["actionLabel"]],
  "{displayHits, plural, one {{displayHits} Hit} other {{displayHits} Hits}}": [["displayHits", "plural", {
    one: [["displayHits"], " Hit"],
    other: [["displayHits"], " Hits"]
  }]],
  "{displayTargetCount, plural, one {{displayTargetCount} Equip} other {{displayTargetCount} Equips}}": [["displayTargetCount", "plural", {
    one: [["displayTargetCount"], " Equip"],
    other: [["displayTargetCount"], " Equips"]
  }]],
  "{equipsCount, plural, one {{unassociatedEquipsCount} of {equipsCount} Equip Unassociated} other {{unassociatedEquipsCount} of {equipsCount} Equips Unassociated}}": [["equipsCount", "plural", {
    one: [["unassociatedEquipsCount"], " of ", ["equipsCount"], " Equip Unassociated"],
    other: [["unassociatedEquipsCount"], " of ", ["equipsCount"], " Equips Unassociated"]
  }]],
  "{numActions, plural, one {# Closed Action} other {# Closed Actions}}": [["numActions", "plural", {
    one: ["#", " Closed Action"],
    other: ["#", " Closed Actions"]
  }]],
  "{numActions, plural, one {# Open Action} other {# Open Actions}}": [["numActions", "plural", {
    one: ["#", " Open Action"],
    other: ["#", " Open Actions"]
  }]],
  "{numSelectedSites, plural, one {# Site} other {# Sites}}": [["numSelectedSites", "plural", {
    one: ["#", " Site"],
    other: ["#", " Sites"]
  }]]
};
exports.messages = messages;